// Importing images
// import Lab from "../img/lab.jpeg";
import Teaching from "../img/teaching.jpg";
// import Classroom from "../img/classroom.jpeg";
import Students from "../img/students.jpeg";
import Students2 from "../img/students2.jpeg";
import Students3 from "../img/students3.jpeg";
// import Classroom2 from "../img/classroom2.jpeg";
// import G1 from "../img/g1.jpg";
// import G2 from "../img/g2.jpg";
// import G3 from "../img/g3.jpg";
// import G4 from "../img/g4.jpg";
// import G6 from "../img/g6.jpeg";
// import G7 from "../img/g7.jpeg";
import G8 from "../img/g8.jpeg";
import G9 from "../img/g9.jpeg";
import G10 from "../img/g10.jpeg";
import G11 from "../img/g11.jpeg";
import G12 from "../img/g12.jpeg";
import G13 from "../img/g13.jpeg";
// import Banner from "../img/banner.jpg";

// Gallery Data
export const GalleryData = [
  { img: Teaching },
  // { img: Classroom },
  { img: Students },
  // { img: Lab },
  // { img: Banner },
  { img: Students2 },
  // { img: Classroom2 },
  { img: Students3 },
  // { img: G1 },
  // { img: G2 },
  // { img: G3 },
  // { img: G4 },
  // { img: G6 },
  // { img: G7 },
  { img: G8 },
  { img: G9 },
  { img: G10 },
  { img: G11 },
  { img: G12 },
  { img: G13 },
];
